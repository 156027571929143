import React from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import { CustomPageProps } from "@utils/types"
import { BaseLayout } from "@layouts/BaseLayout"
import { Map } from "@components/contacts/Map"
import { Information } from "@components/contacts/Information"

const ContactsPage: React.FC<CustomPageProps> = ({
  data,
  pageContext,
  location,
}) => {
  // @ts-ignore
  const content = data.directus.items.contacts
  // @ts-ignore
  const address = content.translations[0].address
  // @ts-ignore
  const seo = data.directus.items.seo
  const seoContent = seo.translations[0]

  return (
    <BaseLayout
      pageContext={pageContext}
      location={location}
      noFooter
      title={seoContent.title_contacts || "Как с нами связаться"}
      description={
        seoContent.description_contacts ||
        "Вы можете приехать к нам в офис по адресу: г. Киев, бульвар Академика Вернадского, 36-В. (територия Будремкомплект НАН Украины)\n" +
          "Позвонить нам по номерам: +38(099)506-50-47, +38(097)496-82-63\n" +
          "Или написать нам на email: office@kosei.org.ua\n" +
          'Также можете оставить заявку на сайте перейдя на страницу "Контакты"'
      }
      image={getSrc(seo.contacts_image.imageFile)}
    >
      <Map />
      <Information
        address={address}
        phoneNumber1={content.phone_number_1}
        phoneNumber2={content.phone_number_2}
        email={content.email}
      />
    </BaseLayout>
  )
}

export const query = graphql`
  query ContactsRuPage {
    directus {
      items {
        contacts {
          email
          phone_number_1
          phone_number_2
          translations(filter: { languages_code: { code: { _eq: "ru-RU" } } }) {
            address
          }
        }
        seo {
          translations(filter: { languages_code: { code: { _eq: "ru-RU" } } }) {
            title_contacts
            description_contacts
          }
          contacts_image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 1200, height: 627)
              }
            }
          }
        }
      }
    }
  }
`

export default ContactsPage
